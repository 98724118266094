<template>
  <div class="hotBusiness">
    <div class="searchWrapper" @keydown.enter.prevent="onSubmit">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" label-position="right" :model="form" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item label="热门地区名称	">
              <el-input
                v-model="form.businessCenterName"
                placeholder="请输入热门地区名称"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.state')">
              <el-select v-model="form.state" placeholder="状态">
                <el-option label="全部" value=""></el-option>
                <el-option label="生效" value="0"></el-option>
                <el-option label="失效" value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button type="primary" icon="el-icon-search" @click="onSubmit" v-if="$route.meta.authority.button.query"
              >{{ $t('button.search') }}</el-button
            >
          </div>
        </div>
        <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
        <div class="col_box h44">
          <div class="col_left">
            <el-button type="primary" icon="el-icon-plus" @click="add" v-if="$route.meta.authority.button.add"
              >{{ $t('button.addto') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table
        header-cell-class-name="header-call-style"
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          v-for="item in tableCols"
          align="center"
          :key="item.prop"
          :formatter="item.formatter"
        ></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="80"
          v-if="
            $route.meta.authority.button.edit ||
            $route.meta.authority.button.status ||
            $route.meta.authority.button.delete
          "
        >
          <template slot-scope="scope">
            <el-dropdown @command="handleCommand($event, scope.row)">
              <el-button type="text" size="small" style="padding: 0"
                >操作<i class="el-icon-arrow-down"
              /></el-button>
              <el-dropdown-menu slot="dropdown">
                <!-- v-if="$route.meta.authority.button.edit" -->
                <el-dropdown-item command="a" v-if="$route.meta.authority.button.edit"
                  >编辑</el-dropdown-item
                >
                <!-- v-if="$route.meta.authority.button.quit" -->
                <el-dropdown-item command="b" v-if="$route.meta.authority.button.status"
                  >置为{{ scope.row.state == 0 ? "失效" : "生效" }}
                </el-dropdown-item>
                <!-- v-if="$route.meta.authority.button.quit" -->
                <el-dropdown-item command="c" v-if="$route.meta.authority.button.delete"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--分页器-->
    <div class="pagerWrapper">
      <div class="block">
        <el-pagination
          v-if="total > 0"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        businessCenterName: "",
        state: "",
      },
      parkNameValue: "",
      loading: false,
      tableCols: [
        {
          prop: "businessCenterName",
          label: "热门区域名称",
          width: "",
        },
        {
          prop: "parkList",
          label: this.$t("list.park_name"),
          width: "",
          formatter: (row) => {
            let parkList = row?.parkList?.map((item) => {
              return item.parkName;
            });
            return parkList?.join();
          },
        },
        {
          prop: "araelist",
          label: this.$t("list.region"),
          width: "",
          formatter: (row) => {
            let araeList = row.araelist.map((item) => {
              return item.areaName;
            });
            return araeList.join();
          },
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row) => {
            if (row.state == 0) {
              return "生效";
            } else if (row.state == 1) {
              return "失效";
            }
          },
        },
        {
          prop: "updatedTime",
          label: this.$t("list.Creation_time"),
          width: "",
        },
      ],
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    onSubmit() {
      let url = "/acb/2.0/businessCenter/list";
      this.$axios
        .get(url, {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            ...this.form,
          },
        })
        .then((res) => {
          if (res.value.list.length == 0 && res.value.total > 0) {
            this.page--;
            this.onSubmit();
          }
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.onSubmit();
    },
    completeValue(item) {
      this.form.parkId = item.parkId;
      this.form.parkName = item.parkName;
    },
    add() {
      this.$router.push("/hotBusinessAdd");
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        // 编辑
        this.$router.push({
          path: "/hotBusinessAdd",
          query: {
            type: "edit",
            id: data.businessCenterId,
          },
        });
      } else if (cmd == "b") {
        // 置为失效
        let url = "/acb/2.0/businessCenter/updateState";
        this.$axios
          .post(
            url,
            {
              data: {
                businessCenterId: data.businessCenterId,
                state: !data.state * 1,
              },
            },
            {
              headers: {
                "Content-Type": "application/json;",
              },
            }
          )
          .then((res) => {
            this.$alert(res.desc);
            this.onSubmit();
          });
      } else if (cmd == "c") {
        // 删除
        this.$confirm("是否删除该热区", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let url = "/acb/2.0/businessCenter/delete";
            this.$axios
              .post(url, {
                data: {
                  businessCenterId: data.businessCenterId,
                },
              })
              .then((res) => {
                this.$alert(res.desc);
                this.onSubmit();
              });
          })
          .catch(() => {});
      }
    },
  },
  mounted() {
    this.onSubmit();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.hotBusiness {
  overflow: hidden;
}
</style>
